<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <v-form ref="form">
      <v-row>
        <v-col v-for="field in fields" :key="field.key" :class="field.classes" v-show="!field.hidden">
          <app-field v-bind="field" @input="bindValue($event, field)" @text-changed="updateTask" />
        </v-col>
        <v-col class="float-right pt-0" lg="2" md="4" sm="12" col="12">
          <div class="float-right">
            <span class="timer">{{ time }}</span>
            <app-icon-btn @click="stopTimer()" v-if="running" btnColor="primary"
              icon="mdi-stop-circle" iconSize="x-large" btnSize="large" :tooltip="$t('actions.stop')" />
            <app-icon-btn @click="startTimer()" v-else btnColor="primary" icon="mdi-play-circle" iconSize="x-large"
              btnSize="large" :tooltip="timeBegan ? $t('actions.resume') : $t('actions.start')" />
            <app-icon-btn @click="pauseTimer()" :disabled="!running"
              icon="mdi-pause" iconSize="small" btnSize="small" :tooltip="$t('actions.pause')" />
            <app-icon-btn @click="discardTime()" :disabled="!ongoingTask.id" icon="mdi-trash-can-outline"
              iconSize="small" btnSize="small" :tooltip="$t('actions.discard')" />
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table :headers="headers" :items="worklogs.items" :options.sync="options"
      :server-items-length="worklogs.count" :loading="loading"
      :no-data-text="$t('messages.emptyState', { entity: $tc('models.worklog.entity') })"
      :no-results-text="$t('messages.emptyState', { entity: $tc('models.worklog.entity') })"
      :footer-props="{itemsPerPageOptions:[10,25,50,100]}"
      @update:options="updateOptions" hide-default-header item-key="id" group-by="date"
    >
    <template v-slot:[`group.header`]="{ group, items }">
      <td :colspan="headers.length" class="date-header">
        <h4>
          {{ header(group) }}
          <span class="float-right">
            {{ totalDurationPerDay(items.map((item) => item.durationInSec)) }}
            <app-icon-btn v-if="items.find((item) => item.jiraTask && !item.isSynced)"
              @click="syncTasks(items.map((item) => item.id))"
              classes="ma-0 pa-0" icon="mdi-sync" iconSize="small" :tooltip="$t('actions.syncAll')" />
          </span>
        </h4>
      </td>
    </template>
    <template v-slot:[`item.startTime`]="{ item }" >
      <v-edit-dialog :return-value.sync="item.startTime"
        @save="updateTaskTime(item)" v-if="!item.isSynced" @open="openTaskTimeDialog(item)">
        {{ item.startTime }} - {{ item.endTime }}
        <template v-slot:input>
          <v-row class="inline-edit-form">
            <v-text-field v-model="startDatetime" class="pt-5 pb-0 col-6" type="time"
              :label="$t('models.worklog.attributes.startTime')" />
            <v-text-field v-model="endDatetime"  class="pt-5 pb-0 col-6"
              :label="$t('models.worklog.attributes.endTime')" type="time"/>
            <v-date-picker v-model="date" flat full-width :max="moment().format('YYYY-MM-DD')"/>
          </v-row>
        </template>
      </v-edit-dialog>
      <div v-else>
        {{ item.startTime }} - {{ item.endTime }}
      </div>
    </template>
    <template v-slot:[`item.duration`]="{ item }" >
      <v-edit-dialog :return-value.sync="item.duration" @save="updateTaskDuration(item)" v-if="!item.isSynced">
        {{ item.duration }}
        <template v-slot:input>
          <v-text-field v-model="item.duration" :label="$t('models.worklog.attributes.duration')"
            single-line v-mask="'##:##:##'" />
        </template>
      </v-edit-dialog>
      <div v-else>
        {{ item.duration }}
      </div>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      <v-edit-dialog :return-value.sync="item.description" @save="updateTaskDescription(item)" v-if="!item.isSynced">
        <a :href="item.jiraUrl" target="blank" class="jira-link">{{ item.jiraTask }}</a>
        <span :class="{'pl-2': item.jiraTask}">{{ item.description }}</span>
        <template v-slot:input>
          <v-text-field v-model="item.description" :label="$t('models.worklog.attributes.description')" single-line />
        </template>
      </v-edit-dialog>
      <div v-else>
        <a :href="item.jiraUrl" target="blank" class="jira-link">{{ item.jiraTask }}</a>
        <span :class="{'pl-2': item.jiraTask}">{{ item.description }}</span>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <app-icon-btn @click="resumeTask(item)" icon="mdi-play" iconSize="small" btnSize="small"
        :tooltip="$t('actions.resume')" />
      <app-icon-btn @click="syncTasks([item.id])" v-if="item.jiraTask && !item.isSynced"
        icon="mdi-sync" iconSize="small" btnSize="small" :tooltip="$t('actions.sync')" />
      <app-icon-btn @click="deleteTask(item.id)" v-if="!item.isSynced"
        icon="mdi-trash-can-outline" iconSize="small" btnSize="small" :tooltip="$t('actions.delete')" />
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      moment,
      time: '00:00:00',
      startTime: null,
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
      valid: true,
      startDatetime: null,
      endDatetime: null,
      date: null,
      title: {
        text: this.$t('layout.accountNavMenu.worklogs'),
        icon: 'mdi-timer'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$t('layout.accountNavMenu.worklogs'),
        to: {name: 'UserWorklogs'},
        exact: true
      }],
      fields: {
        description: {
          type: 'text',
          key: 'description',
          value: '',
          label: this.$t('models.worklog.attributes.description'),
          classes: 'col-lg-6 col-md-4 col-sm-6 col-6 py-0'
        },
        jiraTask: {
          type: 'autocomplete',
          key: 'jiraTask',
          value: '',
          autocompleteAction: this.searchJira,
          options: [],
          itemText: 'value',
          itemValue: 'key',
          isLoading: false,
          clearable: true,
          label: this.$t('models.worklog.attributes.jiraTask'),
          classes: 'col-lg-4 col-md-4 col-sm-6 col-6 py-0'
        }
      },
      headers: [{
        value: 'date',
        align: 'd-none',
        groupable: false,
        sortable: false
      },
      {
        value: 'description',
        groupable: false,
        sortable: false
      },
      {
        value: 'startTime',
        align: 'right',
        groupable: false,
        sortable: false
      },
      {
        value: 'duration',
        align: 'right',
        groupable: false,
        sortable: false
      },
      {
        value: 'actions',
        sortable: false,
        align: 'right',
        groupable: false
      }],
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['startTime'],
        sortDesc: [true],
        mustSort: ['true'],
        multiSort: false
      }
    };
  },
  computed: mapGetters(['worklogs', 'ongoingTask', 'jiraTasks', 'currentUser']),
  methods: {
    ...mapActions(['getWorklogs', 'getOngoingTask', 'createWorklog', 'updateWorklog', 'updateOngoingTask',
      'destroyWorklog', 'syncWorklogs', 'searchJiraTasks']),
    header(group) {
      return moment(group).calendar({
        sameDay: '[Today]', lastDay: '[Yesterday]', lastWeek: 'ddd, DD MMM', sameElse: 'ddd, DD MMM'
      });
    },
    totalDurationPerDay(durations) {
      return moment.duration(this._.sum(durations), 'seconds').format('hh:mm:ss', {trim: false});
    },
    updateOptions() {
      this.loading = true;
      this.getWorklogs({...this.options, filterBy: {completed: true}}).then(() => {
        this.loading = false;
      });
    },
    bindValue(value, field) {
      this.$set(field, 'value', value);
    },
    getFormData() {
      const jiraTask = this.fields.jiraTask.value ? this.fields.jiraTask.value.split('_') : null;
      return {
        description: this.fields.description.value,
        jira_task: jiraTask ? jiraTask[1] : '',
        project: jiraTask ? jiraTask[0] : ''
      };
    },
    searchJira(val) {
      if (this.fields.jiraTask.isLoading) return;

      if (!this._.isNil(val) && val.length >= 3) {
        this.fields.jiraTask.isLoading = true;
        this.searchJiraTasks({searchKey: val, uuid: this.$route.params.uuid}).then(() => {
          this.fields.jiraTask.options = this.jiraTasks.items
            .map((task) => ({
              key: `${task.project}_${task.key}`,
              value: `${task.key} ${task.summary}`
            }));
          this.fields.jiraTask.isLoading = false;
          if (this.ongoingTask.jiraTask) {
            this.fields.jiraTask.value = this.fields.jiraTask.options[0].key;
          }
        });
      }
    },
    setOngoingTask() {
      this.getOngoingTask().then((status) => {
        if (this.successCode(status)) {
          if (this.ongoingTask.jiraTask) {
            this.searchJira(this.ongoingTask.jiraTask);
          }
          if (this.ongoingTask.durationInSec) {
            this.timeBegan = new Date(moment(new Date(), 'hh:mm A').subtract(this.ongoingTask.duration, 'seconds').format());
          } else {
            this.timeBegan = new Date(moment(this.ongoingTask.startTime, 'hh:mm A').format());
          }
          this.fields.description.value = this.ongoingTask.description;
          this.startTimer();
        }
      });
    },
    clockRunning() {
      const currentTime = new Date();
      const timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration);
      const hour = timeElapsed.getUTCHours();
      const min = timeElapsed.getUTCMinutes();
      const sec = timeElapsed.getUTCSeconds();

      this.time = `${this._.padStart(hour, 2, '0')}:${this._.padStart(min, 2, '0')}:${this._.padStart(sec, 2, '0')}`;
    },
    startTimer() {
      if (this.running) return;
      if (this.timeBegan === null) {
        this.resetTimer(false);
        this.timeBegan = new Date();
      }
      if (this.timeStopped !== null) {
        this.stoppedDuration += (new Date() - this.timeStopped);
      }
      this.started = setInterval(this.clockRunning, 10);
      this.running = true;
      if (!this.ongoingTask.id) {
        this.startTask();
      }
    },
    stopTimer() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
      this.stopTask();
    },
    pauseTimer() {
      this.updateOngoingTask({
        id: this.ongoingTask.id,
        form: {duration: this.durationInSeconds(this.time)}
      }).then((status) => {
        if (this.successCode(status)) {
          this.running = false;
          this.timeStopped = new Date();
          clearInterval(this.started);
        }
      });
    },
    resetTimer(resetForm) {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = '00:00:00';
      if (resetForm) {
        this.$refs.form.reset();
      }
    },
    discardTime() {
      this.destroyWorklog({id: this.ongoingTask.id, isOngoingTask: true}).then((status) => {
        if (this.successCode(status)) {
          this.resetTimer(true);
        }
      });
    },
    submitTask(data) {
      this.updateOngoingTask(data).then((status) => {
        if (this.successCode(status)) {
          this.updateOptions();
        }
      });
    },
    startTask() {
      const data = this.getFormData();
      this.createWorklog({
        ...data,
        user_id: this.currentUser.id,
        start_time: moment.utc(moment(new Date())).format()
      });
    },
    stopTask() {
      if (this.$refs.form.validate()) {
        let data = this.getFormData();
        data = {
          ...data,
          duration: this.durationInSeconds(this.time),
          end_time: moment.utc(moment(new Date())).format()
        };
        this.resetTimer(true);
        this.submitTask({id: this.ongoingTask.id, form: data});
      } else {
        this.startTimer();
      }
    },
    updateTask() {
      if (this.ongoingTask.id) {
        this.submitTask({id: this.ongoingTask.id, form: this.getFormData()});
      }
    },
    resumeTask(data) {
      if (this.ongoingTask.id) {
        this.stopTask();
      }
      this.createWorklog({
        user_id: this.currentUser.id,
        start_time: moment.utc(moment(new Date())).format(),
        description: data.description,
        jira_task: data.jiraTask
      }).then(() => {
        this.setOngoingTask();
      });
    },
    syncTasks(ids) {
      this.loading = true;
      this.syncWorklogs(ids).then(() => {
        this.updateOptions();
        this.loading = false;
      });
    },
    deleteTask(id) {
      this.$confirm(this.$t('messages.confirmation.delete', {entity: this.$tc('models.worklog.entity', 1)}), {
        buttonTrueText: this.$t('actions.confirm'),
        buttonFalseText: this.$t('actions.cancel')
      }).then((confirmed) => {
        if (confirmed) {
          this.destroyWorklog({id: id, isOngoingTask: false}).then(() => {
            this.updateOptions();
          });
        }
      });
    },
    openTaskTimeDialog(item) {
      this.startDatetime = moment(item.startTime, 'hh:mm A').format('HH:mm');
      this.endDatetime = moment(item.endTime, 'hh:mm A').format('HH:mm');
      this.date = item.date;
    },
    updateOldTask(data) {
      this.updateWorklog(data).then((status) => {
        if (this.successCode(status)) {
          this.updateOptions();
        }
      });
    },
    updateTaskTime(item) {
      const startTime = moment(this.startDatetime, 'HH:mm');
      const endTime = moment(this.endDatetime, 'HH:mm');
      if (moment(startTime).isValid() && moment(endTime).isValid()) {
        this.updateOldTask({
          id: item.id,
          form: {
            start_time: moment(this.date).set({hours: startTime.hours(), minutes: startTime.minutes()}).format(),
            end_time: moment(this.date).set({hours: endTime.hours(), minutes: endTime.minutes()}).format(),
            duration: endTime.diff(startTime, 'seconds')
          }
        });
      }
    },
    updateTaskDuration(item) {
      this.updateOldTask({
        id: item.id,
        form: {
          end_time: moment(item.startTime, 'hh:mm A').add(this.durationInSeconds(item.duration), 'seconds').format(),
          duration: this.durationInSeconds(item.duration)
        }
      });
    },
    updateTaskDescription(item) {
      this.updateOldTask({
        id: item.id,
        form: {description: item.description}
      });
    }
  },
  created() {
    momentDurationFormatSetup(moment);
    this.setOngoingTask();
  },
  watch: {
    time: {
      handler(val) {
        document.title = val !== '00:00:00' ? `${val} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE;
      },
      immediate: true
    }
  },
  destroyed() {
    document.title = process.env.VUE_APP_TITLE;
  }
};
</script>
<style lang="scss">
.timer {
  width: 100px;
  max-width: 200px;
  padding: 0px;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
}
.jira-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.v-picker__title.primary {
  display: none;
}
.inline-edit-form {
  max-width: 250px !important;
}
</style>
